import React, { useEffect, useState } from "react";
import Loading from "../components/common/Loading";
import TicketDefaultForm from "../components/modules/tickets/TicketDefaultForm";
import { AppHeaderComponent, TicketApi } from "@unity/components";
export default function TicketView({ context, route }) {
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState({});

  const handleCall = async () => {
    const result = await TicketApi.getTicketRecord(route.match.params.id);
    setData(result.data);
    console.log("ticket",result.data);
  };

  useEffect(() => {
    handleCall();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          name={"tickets"}
          subpage={"view"}
          subpage2={data.ticket_name}
          context={context}
          theme={context.theme}
        />
        <div style={{ paddingTop: 140, paddingBottom: 40 }}>
          <TicketDefaultForm data={data} type="view" errors={errors} />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
