import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ApiLoader from "../components/common/ApiLoader";
import KnowledgeBaseSearch from "../components/modules/knowledge/knowledgeBaseSearch";
import TicketCreateForm from "../components/modules/tickets/TicketCreateForm";
import { AppHeaderComponent, TicketApi, CodesApi, NotificationsApi } from "@unity/components";

export default function TicketsCreate({ context, route }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState({ status: false, data: false });
  const [companies, setCompanies] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [com_type, setComType] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [errors, setErrors] = useState({});

  const [tags, setTags] = useState(false);

  let history = useHistory();

  const handleSave = async () => {
    setErrors({});
    if (!data.clientName) {
      setErrors({ company_name: true });
    } else if (!data.clientNumber) {
      setErrors({ clientNumber: true });
    } else if (!data.clientEmail) {
      setErrors({ clientEmail: true });
    } else if (!data.category_id) {
      setErrors({ category_id: true });
    } else {
      setLoading({ status: true, data: "Saving Records.. Please Wait !" });
      data["com_type"] = com_type[data["com_type_id"]];
      const catObj = categories.find((e) => e.id === data["category_id"]);
      data["category"] = catObj.name;


      const res = await TicketApi.createTicket(data);
      console.log(res.data);

      const ticket = res.data;

      if(res.data && data.allocate_id)
      {
        let payload = {
          "user_uuid": data.allocate_id,
          "name": "New Ticket Allocated to You",
          "description": `Name: ${data.ticket_name} - ID: ${ticket.id} - Customer: ${data.clientName}`,
          "link": window.location.hostname + `/tickets/edit/${ticket.id}`,
          "link_action": "View Ticket",
          "poster": context.auth.name,
          "channel": data.allocate_id,
          "event": "notification",
          "message": "New Ticket Allocation"
        }
        const res = await NotificationsApi.saveNotification(payload); // Notify New Allocate
      }

      setLoading({ status: true, data: "Success, Please Wait ...." });
      setTimeout(() => {
        history.push(`/tickets/edit/${ticket.id}`);
      }, 4000);
    }
  };

  const getData = async () => {
    const setup = await TicketApi.getTicketSetup();
    setGroups(setup.data.groups);
    setCompanies(setup.data.clients);
    setCategories(setup.data.types);
    setUsers(setup.data.users);
    setComType(setup.data.com_types);

    const tags = await CodesApi.getTicketStates();
    console.log("Tickets tags", tags);
    setTags(tags.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCompanySelect = async (obj) => {
    const newData = { ...data };
    newData["clientName"] = obj.name;
    newData["clientEmail"] = obj.email;
    newData["clientNumber"] = obj.phone;
    newData["customer_id"] = obj.id;
    const address = await TicketApi.getAddressesIndex(obj.id);
    setAddresses(address.data);
    setData(newData);
  };

  const handleNoCustomerSelect = (name, value) => {
    const newData = { ...data };
    newData["clientName"] = value;

    setData({ ...data, [name]: value });
    setErrors({});
  };

  const handleChange = async (name, value) => {
    setData({ ...data, [name]: value });
    setErrors({});
  };

  return (
    <>
      <AppHeaderComponent
        context={context}
        theme={context.theme}
        name="tickets"
        subpage="create"
        saveBtn={handleSave}
      />
      <ApiLoader status={loading.status} data={loading.data} />
      <div style={{ paddingTop: 140, paddingBottom: 40 }}>
        <TicketCreateForm
          handleChange={handleChange}
          data={data}
          type="create"
          companies={companies}
          groups={groups}
          users={users}
          com_type={com_type}
          categories={categories}
          addresses={addresses}
          handleCompanySelect={handleCompanySelect}
          handleNoCustomerSelect={handleNoCustomerSelect}
          errors={errors}
          tags={tags}
        />
        <KnowledgeBaseSearch />
      </div>
    </>
  );
}
