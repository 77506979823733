import React, { useContext } from "react";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import AuthContext from "../../services/AuthContext";
import AppButton from "../../components/common/AppButton";
import { CanUpdate } from "../../services/Permissions";

export default function TicketActivitiesIndex({
  route,
  context,
  ticket_id,
  data,
}) {
  const columns = [
    { label: "Activity Name", name: "name" },
    { label: "Who worked on it?", name: "completed_by" },
    { label: "Start Date/Time", name: "start" },
    { label: "Finish Date/Time", name: "end" },
    {
      label: "Actions",
      name: "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const id = data[dataIndex].ticket_id;
          const activityid = data[dataIndex].id;
          const edit = CanUpdate("tickets");

          return (
            <div>
              <Link
                to={{
                  pathname: `/tickets/${id}/activity/view/${activityid}`,
                }}
              >
                <AppButton
                  text="view"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>
              {edit && (
                <Link
                  to={{
                    pathname: `/tickets/${id}/activity/edit/${activityid}`,
                  }}
                >
                  <AppButton
                    text="edit"
                    btnIcon="edit"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                </Link>
              )}
            </div>
          );
        },
      },
    },
  ];

  return (
    <div id="companies">
      <div
        className="addCompanyRow"
        style={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <Link to={{ pathname: `/tickets/${ticket_id}/activity/create` }}>
          <AppButton
            text="Add New Activity"
            btnIcon="add"
            style={{
              background: context.theme.sidebar.background,
              color: "white",
            }}
          />
        </Link>

        <Link
          to={{
            pathname: "/activities/communication/client/8/all/index",
            state: { filter: "Ticket" },
          }}
        >
          <AppButton
            text="View Activities"
            btnIcon="visibility"
            style={{
              background: context.theme.sidebar.background,
              color: "white",
            }}
          />
        </Link>
      </div>

      <div className="table">
        <MUIDataTable data={data} columns={columns} />
      </div>
    </div>
  );
}
