import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TicketApi, CodesApi } from "@unity/components";
import { CanDelete, CanUpdate } from "../../../services/Permissions";
import AppButton from "../../common/AppButton";
import FilterTable from "../../common/FilterTable";
import SmallLoader from "../../common/SmallLoader";
import { MenuItem, Select } from "@material-ui/core";
import { InputLabel } from "@mui/material";

export default function AllTickets({ context }) {
  const [data, setData] = useState(false);

  const [tags, setTags] = useState([{}]);
  const [selectedTag, setSelectedTag] = useState({ tag: "" });
  const [total, setTotal] = useState(0);
  const [groups, setGroups] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState({ dep: "" });
  let typedText = "";

  const handleCall = async () => {
    const tags = await CodesApi.getTicketStates();
    setTags(tags.data);
    const res = await TicketApi.indexPaginate(typedText, 0, "", "");
    setData(res.data.data);
    setTotal(res.data.total);

    const department = await TicketApi.getTicketSetup();
    setGroups(department.data.groups);
  };

  useEffect(() => {
    handleCall();
  }, []);

  var select;

  if (CanDelete("tickets")) {
    select = "multiple";
  } else {
    select = "none";
  }

  const changePage = async (page) => {
    await delay(500);
    const data = await TicketApi.indexPaginate(
      typedText,
      page + 1,
      selectedTag.tag,
      selectedDepartment.dep
    );

    setData(data.data.data);
  };

  const delay = (ms) => {
    return new Promise((res) => setTimeout(res, ms));
  };

  const handleTagSelect = async (name, value) => {
    console.log(name, value);

    //const id = value != null ? value : ""

    const data = await TicketApi.indexPaginate(
      typedText,
      0,
      value,
      selectedDepartment.dep
    );

    setData(data.data.data);

    setSelectedTag({
      ...selectedTag,
      [name]: value,
    });
  };
  const handleDepSelect = async (name, value) => {
    console.log(name, value);

    //const id = value != null ? value : ""

    const data = await TicketApi.indexPaginate(
      typedText,
      0,
      selectedTag.tag,
      value
    );

    setData(data.data.data);

    setSelectedDepartment({
      ...selectedTag,
      [name]: value,
    });
  };

  const options = {
    sort: false,
    responsive: "standard",
    print: true,
    rowsPerPage: 15,
    count: total,
    download: false,
    print: false,
    //filter: false,

    serverSide: true,
    onTableChange: async (action, tableState) => {
      switch (action) {
        case "changePage":
          changePage(tableState.page);
          break;
        case "search":
          tableState.searchText != null
            ? (typedText = tableState.searchText)
            : (typedText = "");
          // typedText = tableState.searchText;
          changePage(tableState.page);
          break;

        default:
      }
    },
  };

  const columns = [
    { label: "Date", name: "updated_at" },
    { label: "Ticket Name", name: "ticket_name" },
    { label: "Ref", name: "id" },
    { label: "Nature", name: "category" },
    { label: "Customer", name: "clientName" },
    { label: "Department", name: "tenant_name" },
    { label: "Assigned", name: "allocate" },
    { label: "Status", name: "current_status" },
    {
      label: "Tag",
      name: "tag",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const tagId = data[dataIndex].tag;

          if (tagId != null) {
            const data = tags.find((tag) => tag.id == tagId);
            if (data) return data.name;
            else return "Tag Deleted ";
          } else return "No Tag Set";
        },
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const edit = CanUpdate("tickets");
          return (
            <div className="ticketActionsContainer">
              <Link
                to={{
                  pathname: `/tickets/view/${data[dataIndex].id}`,
                }}
              >
                <AppButton
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    "margin-bottom": "5px",
                  }}
                />
              </Link>

              {edit && (
                <Link
                  to={{
                    pathname: `/tickets/edit/${data[dataIndex].id}`,
                  }}
                >
                  <AppButton
                    btnIcon="edit"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                </Link>
              )}
            </div>
          );
        },
      },
    },
  ];

  if (data) {
    return (
      <>
        <div className="block">
          <div className="container-fluid">
            <div className="form-row">
              <div className="form-group col-lg-6">
                <InputLabel>Select Tag</InputLabel>
                <Select
                  name="tag"
                  value={selectedTag.tag ? selectedTag.tag : ""}
                  onChange={(e) =>
                    handleTagSelect(e.target.name, e.target.value)
                  }
                  fullWidth
                >
                  <MenuItem value={""}>No Tag</MenuItem>
                  {tags &&
                    tags.map((tag, key) => {
                      return (
                        <MenuItem key={key} value={tag.id}>
                          {tag.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
              <div className="form-group col-lg-6">
                <InputLabel>Select Department</InputLabel>
                <Select
                  name="dep"
                  value={selectedDepartment.dep ? selectedDepartment.dep : ""}
                  onChange={(e) =>
                    handleDepSelect(e.target.name, e.target.value)
                  }
                  fullWidth
                >
                  <MenuItem value={""}>No Department</MenuItem>
                  {groups &&
                    groups.map((department, key) => {
                      return (
                        <MenuItem key={key} value={department.id}>
                          {department.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: 20 }}>
          <FilterTable columns={columns} data={data} options={options} />
        </div>
      </>
    );
  } else {
    return <SmallLoader />;
  }
}
