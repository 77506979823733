import { Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Loading from "../components/common/Loading";
import ApiLoader from "../components/common/ApiLoader";
import TicketCreateForm from "../components/modules/tickets/TicketCreateForm";
import TicketActivitiesIndex from "./activity/index";
import {
  AppHeaderComponent,
  TicketApi,
  TicketActivitiesApi,
  CodesApi,
  NotificationsApi
} from "@unity/components";

export default function TicketEdit({ route, context }) {
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState({ status: false, data: false });
  const [companies, setCompanies] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [com_type, setComType] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [tab, setTab] = useState(0);
  const [activities, setActivities] = useState([]);
  const [previousAllocate, setPreviousAllocate] = useState("");

  const [noneCustomer, setNoneCustomer] = useState(true);
  const [tags, setTags] = useState(false);

  const handleTabs = (e, value) => {
    setTab(value);
  };

  function TabPanel(props) {
    const { children, value, index } = props;
    return <div>{value === index && <>{children}</>}</div>;
  }

  let history = useHistory();

  const handleSave = async () => {
    setLoading({ status: true, data: "Saving Records.. Please Wait !" });

    // These are fixes so they can initially save a customer ticket!
    if (data["com_type_id"]) {
      data["com_type"] = com_type[data["com_type_id"]];
    }
    if (data["category_id"]) {
      const catObj = categories.find((e) => e.id === data["category_id"]);
      data["category"] = catObj.name;
    }

    const result = await TicketApi.updateTicket(
      route.match.params.id,
      data,
      data["company_id"],
      data["company_name"]
    );

    if(result.data && data.allocate_id && previousAllocate != "") {
      
      let payload = {
        "user_uuid": data.allocate_id,
        "name": "Ticket Allocated to You",
        "description": `Name: ${data.ticket_name} - ID: ${data.id} - Customer: ${data.clientName}`,
        "link": window.location.hostname + `/tickets/edit/${data.id}`,
        "link_action": "View Ticket",
        "poster": context.auth.name,
        "channel": data.allocate_id,
        "event": "notification",
        "message": "New Ticket Allocation"
      }
      const res = await NotificationsApi.saveNotification(payload); // Notify New Allocate

      payload.user_uuid = previousAllocate;
      payload.channel = previousAllocate;
      payload.name = "Ticket Unallocated From You"
      payload.message = "Ticket Unallocated";

      const res2 = await NotificationsApi.saveNotification(payload); // Notify Old Allocate
    }

    setLoading({ status: true, data: "Success, Please Wait ...." });

    setTimeout(() => {
      history.push("/tickets/index");
    }, 4000);
  };

  const getData = async () => {
    const result = await TicketApi.getTicketRecord(route.match.params.id);
    const setup = await TicketApi.getTicketSetup();
    if (result.data.customer_id != null) {
      const address = await TicketApi.getAddressesIndex(
        result.data.customer_id
      );

      setAddresses(address.data);
      setNoneCustomer(false);
    }
    const tags = await CodesApi.getTicketStates();
    const act = await TicketActivitiesApi.getActivityIndex(
      route.match.params.id
    );
    setActivities(act.data);
    setGroups(setup.data.groups);
    setCompanies(setup.data.clients);
    setCategories(setup.data.types);
    setUsers(setup.data.users);
    setComType(setup.data.com_types);
    setData(result.data);

    setTags(tags.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCompanySelect = async (obj) => {
    const newData = { ...data };
    newData["clientName"] = obj.name;
    newData["clientEmail"] = obj.email;
    newData["clientNumber"] = obj.phone;
    newData["customer_id"] = obj.id;
    const address = await TicketApi.getAddressesIndex(obj.id);
    setAddresses(address.data);
    setData(newData);
  };
  const handleNoCustomerSelect = (name, value) => {
    const newData = { ...data };
    newData["clientName"] = value;

    setData({ ...data, [name]: value });
    setErrors({});
  };

  const handleChange = async (name, value) => {
    if(name === "allocate_id" && previousAllocate === "") {
      setPreviousAllocate( value != data["allocate_id"] ? data["allocate_id"] : "" );
    }

    const newData = { ...data };
    newData[name] = value;
    setData(newData);
  };

  if (data && com_type && companies) {
    return (
      <>
        <AppHeaderComponent
          name={"tickets"}
          subpage={"edit"}
          subpage2={data.ticket_name}
          saveBtn={handleSave}
          context={context}
          theme={context.theme}
        />
        <ApiLoader status={loading.status} data={loading.data} />

        <div style={{ paddingTop: 140 }}>
          <Tabs value={tab} onChange={handleTabs}>
            <Tab label="Ticket Overview" />
            <Tab label={`Ticket Activities, ${data.activity_count}`} />
          </Tabs>

          {tab === 0 && (
            <TicketCreateForm
              groups={groups}
              handleChange={handleChange}
              data={data}
              type="edit"
              companies={companies}
              users={users}
              com_type={com_type}
              categories={categories}
              addresses={addresses}
              handleCompanySelect={handleCompanySelect}
              handleNoCustomerSelect={handleNoCustomerSelect}
              errors={errors}
              noneCustomer={noneCustomer}
              tags={tags}
              context={context}
            />
          )}

          <TabPanel value={tab} index={1}>
            <TicketActivitiesIndex
              data={activities}
              route={route}
              context={context}
              ticket_id={route.match.params.id}
            />
          </TabPanel>
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
