import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import TicketsView from "./pages/view";
import TicketsIndex from "./pages/index";
import TicketsEdit from "./pages/edit";
import TicketsCreate from "./pages/create";
import TicketActivityCreate from "./pages/activity/create";
import TicketActivityEdit from "./pages/activity/edit";
import TicketActivityView from "./pages/activity/view";
export default function Root(props) {
  let context = { ...props };
  let history = useHistory();
  console.log(context);
  return (
    <AuthContext.Provider
      value={{
        ...props,
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    {props.auth.access.tickets["A"] &&
                      props.auth.access.tickets["R"] && (
                        <Route
                          path="/tickets/index"
                          exact
                          component={(route) => (
                            <TicketsIndex
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access.tickets["A"] &&
                      props.auth.access.tickets["R"] && (
                        <Route
                          path="/tickets/view/:id"
                          exact
                          component={(route) => (
                            <TicketsView
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access.tickets["A"] &&
                      props.auth.access.tickets["U"] && (
                        <Route
                          path="/tickets/edit/:id"
                          exact
                          component={(route) => (
                            <TicketsEdit
                              route={route}
                              context={context}
                              history={history}
                            />
                          )}
                        />
                      )}
                    {props.auth.access.tickets["A"] &&
                      props.auth.access.tickets["C"] && (
                        <Route
                          path="/tickets/create"
                          exact
                          component={(route) => (
                            <TicketsCreate
                              route={route}
                              history={history}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access.tickets["A"] &&
                      props.auth.access.tickets["U"] && (
                        <Route
                          exact
                          path="/tickets/:id/activity/create"
                          component={(route) => (
                            <TicketActivityCreate
                              route={route}
                              context={context}
                            />
                          )}
                        ></Route>
                      )}
                    {props.auth.access.tickets["A"] &&
                      props.auth.access.tickets["U"] && (
                        <Route
                          exact
                          path="/tickets/:id/activity/edit/:activityid"
                          component={(route) => (
                            <TicketActivityEdit
                              route={route}
                              context={context}
                            />
                          )}
                        ></Route>
                      )}

                    {props.auth.access.tickets["A"] &&
                      props.auth.access.tickets["U"] && (
                        <Route
                          exact
                          path="/tickets/:id/activity/view/:activityid"
                          component={(route) => (
                            <TicketActivityView
                              route={route}
                              context={context}
                            />
                          )}
                        ></Route>
                      )}
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
