import React, { useState } from "react";
import { Button, CircularProgress  } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { DocumentVaultAPI, TicketApi } from "@unity/components";
import FileBase64 from "react-file-base64";

export default function AttachmentForm(props) {
    const [uploadData, setUploadData] = useState({});
    const [uploading, setUploading] = useState(false);

    const handleUpload = async (e) => {
        setUploadData({
            application_ref: props.context.auth.app_uuid,
            documents: e,
            public: 0
        });
    };

    const handleSave = async () => {
        setUploading(true);
        if( typeof uploadData.documents === undefined ) {
            alert("Please Select a File First");
            setUploading(false);
        }

        const res = await DocumentVaultAPI.storeDocument(uploadData);

        // Create TicketDocument Records in TicketAPI
        if( Array.isArray( res.data ) )
        {
            res.data.map(async (doc) => {
                var payload = {
                    "document_id": doc.id,
                    "document_name": doc.name,
                    "ticket_id": props.ticket_id
                }
                const docRes = await TicketApi.ticketDocumentStore(payload);
            })
        }
        setUploading(false);
    }

    return (
        <>
            <div className="form-row">
                <div className="form-group col-lg-4">
                    <FileBase64 multiple={true} onDone={(e) => handleUpload(e)} />
                    <i>Max Size 5MB</i>
                </div>
                <div className="form-group col-lg-4">
                    <Button
                        disabled={uploading}
                        variant="contained"
                        onClick={() => {
                            handleSave();
                        }}
                        color="success"
                    >
                    {uploading ? (
                        <CircularProgress color="success" size={24} />
                    ) : (
                        <FileUploadIcon/>
                    )}
                    </Button>
                </div>
            </div>
        </>
    )
}