import React, { useState, useEffect, useContext } from "react";
import { Tabs } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Link } from "react-router-dom";

import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import MyTickets from "../components/modules/tickets/MyTickets";
import OpenTickets from "../components/modules/tickets/OpenTickets";
import TicketRequests from "../components/modules/tickets/TicketRequests";
import CustomerReplies from "../components/modules/tickets/CustomerReplies";
import AllTickets from "../components/modules/tickets/AllTickets";
import TicketSettings from "./settings/TicketSettings";
import ReportIndex from "./report";

export default function TicketsIndex({ context, route }) {
  const [tab, setTab] = useState(0);

  const handleTabs = (e, value) => {
    setTab(value);
  };

  function TabPanel(props) {
    const { children, value, index } = props;
    return <div>{value === index && <>{children}</>}</div>;
  }

  return (
    <>
      <AppHeaderComponent
        name="tickets"
        context={context}
        theme={context.theme}
        addNew={() => (
          <Link to={"/tickets/create"}>
            <AppButtonComponent
              text="Add New"
              btnIcon="add"
              stylename="primary"
              style={{
                background: context.theme.top_menu.button_background,
                color: context.theme.top_menu.button_font,
              }}
            />
          </Link>
        )}
      />
      <div style={{ paddingTop: 140 }}>

        {
          context.auth.access.tickets.RA ? (
            <>
              <Tabs
              value={tab}
              onChange={handleTabs}
              style={{ marginBottom: 10 }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="My Tickets" />
              <Tab label="Open Tickets" />
              <Tab label={`Ticket Requests`} />
              <Tab label={`Customer Replies`} />
              <Tab label={`All Tickets`} />
              <Tab label="Report" />
              <Tab label="Settings" />
            </Tabs>
    
            <TabPanel value={tab} index={0}>
              <MyTickets context={context} />
            </TabPanel>
    
            <TabPanel value={tab} index={1}>
              <OpenTickets context={context} />
            </TabPanel>
    
            <TabPanel value={tab} index={2}>
              <TicketRequests context={context} />
            </TabPanel>
    
            <TabPanel value={tab} index={3}>
              <CustomerReplies context={context} />
            </TabPanel>
    
            <TabPanel value={tab} index={4}>
              <AllTickets context={context} />
            </TabPanel>
    
            <TabPanel value={tab} index={5}>
              <ReportIndex context={context} />
            </TabPanel>
    
            <TabPanel value={tab} index={6}>
              <TicketSettings context={context} />
            </TabPanel>
          </>
          ) : (
            <>
            <Tabs
              value={tab}
              onChange={handleTabs}
              style={{ marginBottom: 10 }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="My Tickets" />
              <Tab label={`All Tickets`} />
              <Tab label="Settings" />
            </Tabs>
    
            <TabPanel value={tab} index={0}>
              <MyTickets context={context} />
            </TabPanel>
    
            <TabPanel value={tab} index={1}>
              <AllTickets context={context} />
            </TabPanel>
    
            <TabPanel value={tab} index={2}>
              <TicketSettings context={context} />
            </TabPanel>
            </>
          )
        }

      </div>
    </>
  );
}
