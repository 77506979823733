import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import DataTable from "../../components/common/DataTable";
import { TicketApi, PersonnelApi } from "@unity/components";
import { Button, InputLabel } from "@mui/material";
import ReportBox from "../../components/modules/tickets/ReportBox";

const escapeRegExp = (value) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

export default function ReportIndex() {
  const [data, setData] = useState(false);

  const [rowsTotal, setRowTotal] = useState(0);

  const [dateData, setDateData] = useState({});
  const [boxData, setBoxData] = useState({
    openCount: 0,
    closedCount: 0,
  });
  const [remaining, setRemaining] = useState({ remainingOpen: 0 });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(15);
  const [users, setUsers] = useState({});

  const getData = async () => {
    const res = await TicketApi.getTicketOpen();
    setRemaining({ remainingOpen: res.data.length });
  };

  const getPersonnel = async () => {
    const res = await PersonnelApi.getPersonnel();
    let users = {};
    for(let i = 0; i < res.data.length; i++) users[res.data[i].uuid] = res.data[i].name;
    setUsers(users);
  }

  useEffect(() => {
    getData();
    getPersonnel();
  }, []);

  //Same as MUI field name is the same name as from the data set, headername is the name of the header
  //Flex is used to make sure all of the columns are the same width
  const columns = [
    { field: "id", headerName: "Ticket Ref" },
    {
      field: "clientName",
      headerName: "Customer Name",
      flex: 1,
      valueFormatter: (params) => {
        return params.value === null ? "No Customer name" : params.value;
      },
    },
    {
      field: "ticket_name",
      headerName: "Ticket Name",
      flex: 1,
      valueFormatter: (params) => {
        return params.value === null ? "No ticket name" : params.value;
      },
    },
    {
      field: 'department_name',
      headerName: "Department Name",
      flex: 1
    },
    {
      field: "current_status",
      headerName: "Status",
      valueFormatter: (params) => {
        return params.value === 0 ? "Open" : "Closed";
      },
      flex: 1
    },
    {
      field: "allocate_id",
      headerName: "Allocated To",
      valueFormatter: (params) => {
        return users[params.value] != undefined ? users[params.value] : "Unallocated";
      },
      flex: 1
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      type: "date",
      valueFormatter: (params) => {
        const newDate = new Date(params.value);
        return `${newDate.getDate()}-${newDate.getMonth() +1}-${newDate.getFullYear()} ${newDate.getHours()}:${newDate.getMinutes()}`;
      },
    },
  ];

  const handlePageChange = async (page, size = 15) => {
    const res = await TicketApi.reportPaginate(dateData, page + 1, size);
    setData(res.data);
  };

  const handleDateSearch = async () => {
    if (validation()) {
      setLoading(true);
      const res = await TicketApi.reportPaginate(dateData, 1, pageSize);
      setData(res.data);

      // getNumberClosedAndOpen(res);
      setBoxData({
        ...boxData,
        openCount: res.openCount,
        closedCount: res.closedCount,
      });
      setRowTotal(res.total);
      setLoading(false);
    }
  };

  const getNumberClosedAndOpen = (res) => {
    const open = res.data.filter((item) => {
      return item.current_status === 0;
    });
    const closed = res.data.filter((item) => {
      return item.current_status === 1;
    });

    setBoxData({
      ...boxData,
      openCount: open.length,
      closedCount: closed.length,
    });
  };

  const validation = () => {
    if (typeof dateData.startDate === "undefined") {
      setErrors({ ...errors, startDate: true });
      return false;
    }
    if (typeof dateData.endDate === "undefined") {
      setErrors({ ...errors, endDate: true });
      return false;
    }

    if (dateData.startDate <= dateData.endDate) {
      console.log("Start Date is less than end date");
    }

    setErrors({ ...errors, startDate: false, endDate: false });
    return true;
  };

  const options = {
    pageSize: pageSize,
    rowCount: rowsTotal,
    onPageSizeChange: (size) => {setPageSize(size); setData(false); handlePageChange(0, size)},
    rowsPerPageOptions: [15, 50, 100],
    handlePageChange: handlePageChange,
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
        >
          <div style={{ flex: 1, marginRight: 5 }}>
            <ReportBox
              title={"Opened In Time Frame"}
              data={boxData.openCount}
              bg="rgb(99 149 249)"
            />
          </div>
          <div style={{ flex: 1, marginRight: 5 }}>
            <ReportBox
              title={"Closed In Time Frame"}
              data={boxData.closedCount}
              bg="rgb(99 149 249)"
            />
          </div>
          <div style={{ flex: 1, marginRight: 5 }}>
            <ReportBox
              title={"Remaining Open"}
              data={remaining.remainingOpen}
              bg="rgb(99 149 249)"
            />
          </div>
        </div>

        <div className="block">
          <div className="container-fluid">
            <div className="form-row">
              <div className="form-group col-lg-6">
                <InputLabel>Start Date</InputLabel>
                <TextField
                  name="startDate"
                  type="datetime-local"
                  value={dateData.startDate}
                  onChange={(e) =>
                    setDateData({
                      ...dateData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  fullWidth
                  error={errors.startDate}
                  helperText={errors.startDate ? "Please Enter A Date" : ""}
                />
              </div>
              <div className="form-group col-lg-6">
                <InputLabel>End Date</InputLabel>
                <TextField
                  name="endDate"
                  type="datetime-local"
                  value={dateData.endDate}
                  onChange={(e) =>
                    setDateData({
                      ...dateData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  fullWidth
                  error={errors.endDate}
                  helperText={errors.endDate ? "Please Enter A Date" : ""}
                />
              </div>
              <div className="form-group col-lg-12">
                <Button
                  variant="contained"
                  onClick={() => {
                    handleDateSearch();
                  }}
                  fullWidth
                  disable={loading}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
        {data && users && (
          <DataTable
            columns={columns}
            rows={data}
            pagination={true}
            options={options}
          />
        )}
      </div>
    </>
  );
}
