import { InputLabel } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import React from "react";

export default function TicketAcitivtyCreateForm({ data, handleChange }) {
  return (
    <div className="block">
      <div className="container-fluid">
        <div className="form-row">
          <div className="form-group col-lg-12">
            <h4>Ticket Activity</h4>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-3">
            <TextField
              required
              label="Activity Name"
              type="text"
              name="name"
              fullWidth
              value={data.name} //In the form component, create the first input, and set its value to the current state of the ticket name
              onChange={(e) => handleChange(e)}
              helperText="Give the activity a name..."
            />
          </div>
          <div className="form-group col-lg-3">
            <TextField
              required
              label="Completed By"
              type="text"
              fullWidth
              name="completed_by"
              helperText="Who worked on this"
              value={data.completed_by}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="form-group col-lg-3">
            <InputLabel
              style={{
                fontSize: 12,
                marginBottom: 2,
                color: "#2100b7",
              }}
            >
              Date/Time Started
            </InputLabel>
            <TextField
              required
              type="datetime-local"
              fullWidth
              name="start"
              helperText="data and time started"
              value={data.start}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="form-group col-lg-3">
            <InputLabel
              style={{
                fontSize: 12,
                marginBottom: 2,
                color: "#2100b7",
              }}
            >
              Date/Time Completed
            </InputLabel>
            <TextField
              required
              type="datetime-local"
              fullWidth
              name="end"
              helperText="data and time started"
              value={data.end}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-12">
            <TextField
              label="Activity Notes"
              type="text"
              name="notes"
              fullWidth
              value={data.notes} //In the form component, create the first input, and set its value to the current state of the ticket name
              onChange={(e) => handleChange(e)}
              fullWidth
              multiline
              helperText="Give the activity a name..."
            />
          </div>
        </div>
      </div>
    </div>
  );
}
