import React, { useState, useEffect } from "react";
import ApiLoader from "../../components/common/ApiLoader";
import Loading from "../../components/common/Loading";
import TicketAcitivtyCreateForm from "../../components/modules/tickets/activity/TicketActivityCreateForm";
import { AppHeaderComponent, TicketActivitiesApi } from "@unity/components";

export default function TicketActivityView({ route, context }) {
  const [loading, setLoading] = useState({ status: false });
  const id = route.match.params.id;
  const activityid = route.match.params.activityid;
  const [data, setData] = useState(false);

  const handleCall = async () => {
    const result = await TicketActivitiesApi.getActivityRecord(id, activityid);
    setData(result.data);
  };

  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setData(data);
  };

  useEffect(() => {
    handleCall();

    return () => {};
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name={"tickets"}
          subpage={"activity"}
          subpage2={"view"}
        />
        <ApiLoader status={loading.status} data={loading.data} />
        <div style={{ paddingTop: 140 }}>
          <TicketAcitivtyCreateForm data={data} handleChange={handleChange} />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
