import React, { useState, useEffect } from "react";
import { Grid, Card, Button, CircularProgress  } from "@mui/material";
import { DocumentVaultAPI, TicketApi } from "@unity/components";

export default function AttachmentList(props) {
    const [uploads, setUploads] = useState([]);

    const getUploads = async () => {
        var payload = {
            "ticket_id" : props.ticket_id,
            "page" : 1,
            "per_page": 100
        }
        const res = await TicketApi.ticketDocumentIndexForTicket(payload);

        setUploads(res.data.items);
    }

    const generateLink = async (doc_id) => {
        const res = await DocumentVaultAPI.generateTempLink(doc_id);
        window.open(res.data, "_blank");
    }

    const deleteDocument = async (doc_id) => {
        const res = await TicketApi.ticketDocumentDelete(doc_id);
        console.log("res chekcer", res);

        getUploads();
    }

    useEffect(() => {
        getUploads();
    }, []);

    return uploads.length > 0 ? (
        
        <Grid container spacing={2}>
            {uploads.map((attach) => {
                return (
                <Grid item xs={4}>
                    <Card style={{ padding: "5px" }}>
                        <div className="container-fluid">
                            <div className="row" >
                                <p>{attach.document_name}</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <Button
                                        variant="contained"
                                        color="success"
                                        fullWidth
                                        onClick={() => {generateLink(attach.document_id)}}
                                    >
                                        Download
                                    </Button>
                                </div>
                                <div className="col-lg-6">
                                    <Button
                                        variant="contained"
                                        color="error"
                                        fullWidth
                                        onClick={() => {deleteDocument(attach.id)}}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Grid>
                )
            })}
        </Grid>
    ) : (
        <p>No Attachments Found</p>
    )
}