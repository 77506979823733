import React, { useEffect, useState } from "react";
import { InputLabel, MenuItem, Select, TextField } from "@material-ui/core";

export default function TicketDefaultForm(props) {

  const cssStyle = {
    style: {
      readOnly: true
    }
  }

  if (!props.data.partial && props.data.customer_updated)
  {
    cssStyle.style.backgroundColor = "#ffb5b5";
  }

  return (
    <>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                label="Ticket Name"
                name="ticket_name"
                onChange={
                  props.type !== "view"
                    ? (e) => props.handleChange(e.target.name, e.target.value)
                    : ""
                }
                fullWidth
                value={props.data.ticket_name}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              {props.type === "view" ? (
                <TextField
                  label="Nature of Ticket"
                  name="ticketNature"
                  onChange={
                    props.type !== "view"
                      ? (e) => props.handleChange(e.target.name, e.target.value)
                      : ""
                  }
                  fullWidth
                  value={props.data.category}
                />
              ) : (
                <>
                  <InputLabel style={{ fontSize: "0.8rem", marginBottom: 5 }}>
                    Nature of Ticket
                  </InputLabel>
                  <Select
                    name="category"
                    className="ticketNature"
                    fullWidth
                    onChange={(e) =>
                      props.handleChange(e.target.name, e.target.value)
                    }
                  >
                    {props.ticket_categories.map((item, key) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </>
              )}
            </div>
            <div className="form-group col-lg-4">
              {props.type === "view" ? (
                <TextField
                  label="Service"
                  name="contactType"
                  onChange={
                    props.type !== "view"
                      ? (e) => props.handleChange(e.target.name, e.target.value)
                      : ""
                  }
                  fullWidth
                  value={props.data.com_type}
                />
              ) : (
                <>
                  <InputLabel style={{ fontSize: "0.8rem", marginBottom: 5 }}>
                    Service
                  </InputLabel>

                  <Select
                    name="com_type"
                    fullWidth
                    value={
                      props.data.com_type != null
                        ? props.data.com_type
                        : props.data.com_type
                    }
                    onChange={(e) =>
                      props.handleChange(e.target.name, e.target.value)
                    }
                  >
                    {props.com_types.map((item, key) => {
                      return <MenuItem value={item.name}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </>
              )}
            </div>
            <div className="form-group col-lg-4">
              <TextField
                label="Ticket Reference"
                name="ticket_id"
                onChange={
                  props.type !== "view"
                    ? (e) => props.handleChange(e.target.name, e.target.value)
                    : ""
                }
                fullWidth
                value={props.data.id}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Date Time Reported"
                name="reportedTime"
                onChange={
                  props.type !== "view"
                    ? (e) => props.handleChange(e.target.name, e.target.value)
                    : ""
                }
                fullWidth
                value={props.data.created_at}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                label="Current Status"
                name="current_status"
                onChange={
                  props.type !== "view"
                    ? (e) => props.handleChange(e.target.name, e.target.value)
                    : ""
                }
                fullWidth
                value={props.data.current_status}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-6">
              <TextField
                label="Company Name"
                name="clientName"
                onChange={
                  props.type !== "view"
                    ? (e) => props.handleChange(e.target.name, e.target.value)
                    : ""
                }
                fullWidth
                value={props.data.clientName}
              />
            </div>
            <div className="form-group col-lg-6">
              <TextField
                label="Company Address"
                name="clientFirstLine"
                onChange={
                  props.type !== "view"
                    ? (e) => props.handleChange(e.target.name, e.target.value)
                    : ""
                }
                fullWidth
                value={props.data.address_id}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Client Email Address"
                name="clientEmail"
                onChange={
                  props.type !== "view"
                    ? (e) => props.handleChange(e.target.name, e.target.value)
                    : ""
                }
                fullWidth
                value={props.data.clientEmail}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                label="Client Contact Number"
                name="clientNumber"
                onChange={
                  props.type !== "view"
                    ? (e) => props.handleChange(e.target.name, e.target.value)
                    : ""
                }
                fullWidth
                value={props.data.clientNumber}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                label="Reported By"
                name="createdName"
                onChange={
                  props.type !== "view"
                    ? (e) => props.handleChange(e.target.name, e.target.value)
                    : ""
                }
                fullWidth
                value={props.data.reported_by}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              {props.type === "view" ? (
                <TextField
                  label="Allocated Department"
                  name="tenant_name"
                  onChange={
                    props.type !== "view"
                      ? (e) => props.handleChange(e.target.name, e.target.value)
                      : ""
                  }
                  fullWidth
                  value={props.data.tenant_name}
                />
              ) : (
                <>
                  <InputLabel style={{ fontSize: "0.8rem", marginBottom: 5 }}>
                    Allocated Department
                  </InputLabel>
                  <Select
                    onChange={(e) =>
                      props.handleChange(e.target.name, e.target.value)
                    }
                    name="client_ref"
                    value={
                      props.data.client_ref != null ? props.data.client_ref : ""
                    }
                    fullWidth
                  >
                    {props.groups.map((item, key) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </>
              )}
            </div>
            <div className="form-group col-lg-4">
              {props.type === "view" ? (
                <TextField
                  label="Who is this allocated to ?"
                  name="allocate"
                  onChange={
                    props.type !== "view"
                      ? (e) => props.handleChange(e.target.name, e.target.value)
                      : ""
                  }
                  fullWidth
                  value={props.data.allocate}
                />
              ) : (
                <>
                  <InputLabel style={{ fontSize: "0.8rem", marginBottom: 5 }}>
                    Who is this allocated to?
                  </InputLabel>
                  <Select
                    name="allocated_user"
                    onChange={(e) =>
                      props.handleChange(e.target.name, e.target.value)
                    }
                    fullWidth
                    value={props.allocate_id}
                  >
                    {props.users.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.uuid}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </>
              )}
            </div>
          </div>
        </div>

        {props.data.customer_closed && (
            <>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <div className="form-group col-lg-12">
                    <TextField
                      name="customerClosure"
                      fullWidth
                      value="Customer has requested the closure of the ticket!"
                      inputProps={{
                        style: {
                          readOnly: true,
                          color: "red"
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

        {props.data.customer_update && (
            <>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <div className="form-group col-lg-12">
                    <TextField
                      label="Customer Update"
                      name="customerNotes"
                      multiline
                      fullWidth
                      value={props.data.customer_update}
                      inputProps={cssStyle}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

        <div className="form-row">
          <div className="form-group col-lg-12">
            <div className="form-group col-lg-12">
              <TextField
                label="Notes"
                name="additionalNotes"
                multiline
                onChange={
                  props.type !== "view"
                    ? (e) => props.handleChange(e.target.name, e.target.value)
                    : ""
                }
                fullWidth
                value={props.data.notes}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
