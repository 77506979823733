import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AttachmentForm from "./AttachmentForm";
import AttachmentList from "./AttachmentList";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

export default function TicketCreateForm(props) {
  const [isCustomer, setIsCustomer] = useState(
    props.type === "edit" ? props.noneCustomer : false
  );

  const status = [
    { id: 0, name: "Open" },
    { id: 1, name: "Closed" },
  ];

  const getSelectedItem = () => {
    const item = props.companies.find(
      (opt) => opt.id === props.data.customer_id
    );
    return item;
  };

  const cssStyle = {
    style: {
      readOnly: true,
    },
  };

  if (!props.data.partial && props.data.customer_updated) {
    cssStyle.style.backgroundColor = "#ffb5b5";
  }

  useEffect(() => {
    getSelectedItem();
  }, []);

  return (
    <div>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-10">
              <TextField
                label="Ticket Name"
                name="ticket_name"
                value={props.data.ticket_name}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                placeholder="Give the ticket a name..."
                fullWidth
              />
            </div>

            <div className="form-group col-lg-2">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>No</Typography>
                      <Switch
                        onChange={() => {
                          !isCustomer
                            ? setIsCustomer(true)
                            : setIsCustomer(false);
                        }}
                        checked={isCustomer}
                      />
                      <Typography>Yes</Typography>
                    </Stack>
                  }
                  label="Not a Customer?"
                  labelPlacement="Top"
                />
              </FormGroup>
            </div>
          </div>

          <div className="form-row">
            {!isCustomer ? (
              <div className="form-group col-lg-4">
                <Autocomplete
                  className="company_autocomplete"
                  value={getSelectedItem()}
                  fullWidth
                  onChange={(e, val) => props.handleCompanySelect(val)}
                  options={props.companies}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={props.errors.company_name}
                      label="Company Name"
                    />
                  )}
                />
              </div>
            ) : (
              <div className="form-group col-lg-4">
                <TextField
                  label="Customer Name"
                  name="clientName"
                  value={props.data.clientName ? props.data.clientName : ""}
                  onChange={(e) =>
                    props.handleNoCustomerSelect(e.target.name, e.target.value)
                  }
                  fullWidth
                />
              </div>
            )}

            {!isCustomer && (
              <div className="form-group col-lg-4">
                <InputLabel style={{ margin: 0, fontSize: "1rem" }}>
                  Please Choose an Address
                </InputLabel>
                <Select
                  onChange={(e) =>
                    props.handleChange(e.target.name, e.target.value)
                  }
                  fullWidth
                  name="address_id"
                  value={props.data.address_id}
                  disabled={props.addresses && props.addresses.length === 0}
                >
                  {props.addresses &&
                    props.addresses.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.address_id}>
                          {`${item.address_first_line} ${item.address_town} ${item.address_postcode}`}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
            )}
            <div className="form-group col-lg-4">
              <InputLabel style={{ margin: 0, fontSize: "1rem" }}>
                Nature of ticket:
              </InputLabel>
              <Select
                name="category_id"
                value={props.data.category_id}
                fullWidth
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                error={props.errors.category_id}
              >
                {props.categories.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>

          <div className="form-row">
            <div className="col-lg-4">
              <InputLabel style={{ margin: 0, fontSize: "1rem" }}>
                Which business will this be allocated to?:
              </InputLabel>

              <Select
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth
                name="client_ref"
                value={props.data.client_ref}
              >
                {props.groups.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>

            <div className="col-lg-4">
              <InputLabel style={{ margin: 0, fontSize: "1rem" }}>
                Who is assigned to this?
              </InputLabel>
              <Select
                fullWidth
                style={{ zIndex: 999 }}
                name="allocate_id"
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                value={props.data.allocate_id}
              >
                {props.users.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.uuid}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className="col-lg-4">
              <InputLabel style={{ margin: 0, fontSize: "1rem" }}>
                Type of Contact:
              </InputLabel>
              <Select
                fullWidth
                displayEmpty
                name="com_type_id"
                value={props.data.com_type_id}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
              >
                {props.com_type.map((item, key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className="form-row">
            <div className="col-lg-4">
              <InputLabel style={{ margin: 0, fontSize: "1rem" }}>
                Tag Type
              </InputLabel>
              <Select
                fullWidth
                displayEmpty
                name="tag"
                value={props.data.tag}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
              >
                {props.tags &&
                  props.tags.map((tag, key) => {
                    return (
                      <MenuItem key={key} value={tag.id}>
                        {tag.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
          </div>
        </div>
      </div>

      <div className="block">
        <div className="container-fluid">
          {props.data.customer_closed && (
            <>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <TextField
                    name="customerClosure"
                    fullWidth
                    value="Customer has requested the closure of the ticket!"
                    inputProps={{
                      style: {
                        readOnly: true,
                        color: "red",
                      },
                    }}
                  />
                </div>
              </div>
            </>
          )}

          {props.type !== "create" && (
            <div className="form-row">
              <div className="col-lg-4">
                <InputLabel>Current Status</InputLabel>
                <Select
                  fullWidth
                  name="current_status"
                  onChange={(e) =>
                    props.handleChange(e.target.name, e.target.value)
                  }
                  value={props.data.current_status}
                >
                  {status.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="col-lg-4">
                <InputLabel>Created By</InputLabel>
                <TextField
                  name="created_by_name"
                  value={props.data.created_by_name}
                  disabled
                  fullWidth
                />
              </div>
            </div>
          )}

          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                label="Product Name"
                name="product_name"
                value={props.data.product_name}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                placeholder="Enter a product name"
                fullWidth
              />
            </div>
          </div>

          {props.data.customer_update && (
            <>
              <div className="form-row">
                <div className="form-group col-lg-12">
                  <TextField
                    label="Customer Update"
                    name="customerNotes"
                    multiline
                    fullWidth
                    value={props.data.customer_update}
                    inputProps={cssStyle}
                  />
                </div>
              </div>
            </>
          )}

          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                label="Additional Notes:"
                name="notes"
                value={props.data.notes}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                placeholder="Any additional notes? Add them here!..."
                multiline
                fullWidth
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                label="SLA Notes"
                name="sla_notes"
                value={props.data.sla_notes}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                placeholder="Enter sla notes"
                fullWidth
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                label="Reported By"
                name="reported_by"
                value={props.data.reported_by}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                placeholder="Who reported this"
                fullWidth
              />
            </div>

            <div className="form-group col-lg-4">
              <InputLabel style={{ fontSize: 13, marginBottom: 3 }}>
                Contact Phone Number
              </InputLabel>
              <TextField
                name="clientNumber"
                value={props.data.clientNumber}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                placeholder="Customer Contact Phone Number"
                fullWidth
                error={props.errors.clientNumber}
              />
            </div>

            <div className="form-group col-lg-4">
              <InputLabel style={{ fontSize: 13, marginBottom: 3 }}>
                Contact Email
              </InputLabel>

              <TextField
                name="clientEmail"
                value={props.data.clientEmail}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                placeholder="Customer Contact Email"
                fullWidth
                error={props.errors.clientEmail}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="col-lg-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.data.ticket_prioritised}
                    onChange={(e) =>
                      props.handleChange(e.target.name, e.target.checked)
                    }
                    name="ticket_prioritised"
                    color="primary"
                  />
                }
                label="Priority Ticket"
              />
            </div>
          </div>
        </div>
      </div>

      {props.type === "edit" &&
      (
        <div className="block">
          <div className="container-fluid">
            <h4>Attachments:</h4>
            <AttachmentForm
              context={props.context}
              ticket_id={props.data.id}
            />
            <AttachmentList
              context={props.context}
              ticket_id={props.data.id}
            />
          </div>
        </div>
      )}

    </div>
  );
}
