import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TicketApi, CodesApi } from "@unity/components";
import { CanDelete, CanUpdate } from "../../../services/Permissions";
import AppButton from "../../common/AppButton";
import FilterTable from "../../common/FilterTable";
import SmallLoader from "../../common/SmallLoader";
import { Icon } from "@material-ui/core";
import "./ticketIndex.css";

export default function MyTickets({ context }) {
  const [data, setData] = useState(false);
  const [tags, setTags] = useState([{}]);
  const handleCall = async () => {
    const tags = await CodesApi.getTicketStates();
    console.log("Tickets tags", tags);
    setTags(tags.data);
    const res = await TicketApi.getTicketIndexByActiveUser();
    setData(res.data);
  };

  useEffect(() => {
    handleCall();
  }, []);

  var select;

  if (CanDelete("tickets")) {
    select = "multiple";
  } else {
    select = "none";
  }
  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: select,
  };

  console.log("DATA", data);
  const columns = [
    { label: "Date Created", name: "created_at" },
    { label: "Ticket Name", name: "ticket_name" },
    { label: "Ref", name: "id" },
    { label: "Nature", name: "category" },
    { label: "Customer", name: "clientName" },
    { label: "Department", name: "tenant_name" },
    { label: "Assigned", name: "allocate" },
    {
      label: "Status",
      name: "current_status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].current_status === 0) {
            return "Open";
          } else {
            return "Closed";
          }
        },
      },
    },
    {
      label: "Tag",
      name: "tag",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const tagId = data[dataIndex].tag;

          if (tagId != null) {
            const data = tags.find((tag) => tag.id == tagId);

            return data.name;
          } else return "No Tag Set";
        },
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const edit = CanUpdate("tickets");

          return (
            <div>
              <Link
                to={{
                  pathname: `/tickets/view/${data[dataIndex].id}`,
                }}
              >
                <AppButton
                  text="View"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>

              {edit && (
                <Link
                  to={{
                    pathname: `/tickets/edit/${data[dataIndex].id}`,
                  }}
                >
                  <AppButton
                    text="edit"
                    btnIcon="edit"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                </Link>
              )}
            </div>
          );
        },
      },
    },
    {
      label: "Priority",
      name: "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].ticket_prioritised ? (
            <div class="priority">
              <Icon>whatshot</Icon>
            </div>
          ) : (
            <></>
          );
        },
      },
    },
  ];

  if (data) {
    return <FilterTable columns={columns} data={data} options={options} />;
  } else {
    return <SmallLoader />;
  }
}
